import { scrollTarget } from './scrollTarget.js';

export function hashScroll() {
  const $container = $('.l-container'),
        protocolRegexp = /^https?:\/\//,
        idRegexp = /^[a-zA-Z]+[\w|\-|:|.]+$/,
        currentAnchor = location.href.split('#');
  let $target;

  $container.on('click', 'a', function(event) {
    const thisAnchor = this.href.split('#');

    if (event.isDefaultPrevented() || !protocolRegexp.test(this.href) || this.target === '_blank') {
      return;
    }
    if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1 && idRegexp.test(thisAnchor[1])) {
      $target = $(document).find('#' + thisAnchor[1]);
      scrollTarget($target, 500, $.noop);
      event.preventDefault();
    }
  });
  $(window).on('load', function() {
    if (location.hash && idRegexp.test(location.hash.slice(1))) {
      $target = $(document).find(location.hash);
      scrollTarget($target, 500, $.noop);
      if ('replaceState' in history) {
        history.replaceState('', document.title, window.location.pathname + window.location.search);
      }
    }
  });
}
