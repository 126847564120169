export function googleMaps() {
  const headElement = document.querySelector('head');
  const scriptElement = document.createElement('script');

  window.initGoogleMaps = function() {
    const styleOptions = [
      {
        'stylers': [
          {
            'saturation': -100
          }
        ]
      }
    ];
    const mapList = [
      {
        $element : $('#map1'),
        option: {
          center: { lat: 35.681941, lng: 139.807141 },
          zoom: 16,
          disableDefaultUI: true,
          styles: styleOptions
        },
        icon : {
          url: '/img/img_pin.png',
          anchor: new google.maps.Point(39, 98),
          scaledSize: new google.maps.Size(78, 98)
        }
      }
    ];

    $.each(mapList, function(index) {
      let map;

      if (mapList[index].$element.length === 0) {
        return true;
      }
      map = new google.maps.Map(mapList[index].$element[0], mapList[index].option);
      new google.maps.Marker({
        position: mapList[index].option.center,
        map: map,
        icon : mapList[index].icon
      });
    });
  };
  scriptElement.src = 'https://maps.googleapis.com/maps/api/js?callback=initGoogleMaps&key=AIzaSyChv4ghGp1Io3ER0M31ucDyDKl0ezJvx5U';
  headElement.appendChild(scriptElement);
}
