export function navigation() {
  const vueHub = new Vue(),
        idRegexp = new RegExp(/^[a-zA-Z]+[\w|\-|:|.]+$/),
        currentAnchor = location.href.split('#');
  let toggle;

  new Vue({
    el: '.l-header',
    data: {
      isEnable: true,
      isFixed: false,
      isActive: false,
      enableTransition: false,
      sectionName: '',
    },
    mounted() {
      const $window = $(window),
            $sectionList = $('.l-main > .c-sec'),
            sectionListLength = $sectionList.length;
      let navigationNumber = -1,
          isChangeFixed = false,
          scrollY = 0,
          prevScrollY = 0,
          scrollDirection = 0;

      $(this.$el).data('vm-header', this);
      $window.on('scroll', () => {
        const winScrollTop = window.pageYOffset;
        let changeNavigationNumber = -1;

        for (var i = 0; i < sectionListLength; i++) {
          if (winScrollTop >= $sectionList.eq(i).offset().top) {
            changeNavigationNumber = i;
          } else {
            break;
          }
        }
        if (navigationNumber !== changeNavigationNumber) {
          const elementId = $sectionList.eq(changeNavigationNumber).attr('id');
          this.sectionName = elementId ? `is-${elementId}` : '';
          navigationNumber = changeNavigationNumber;
        }
        if (navigationNumber === -1) {
          this.sectionName = '';
        }
      });
      $window.on('scroll', () => {
        prevScrollY = scrollY;
        scrollY = window.pageYOffset;
        scrollDirection = scrollY - prevScrollY;

        if (isChangeFixed) {
          isChangeFixed = false;
          this.enableTransition = true;
        }
        if (scrollY <= 0) {
          this.isFixed = false;
          this.isActive = false;
          this.enableTransition= false;
        } else {
          if (scrollY > 130) {
            if (!this.isFixed) {
              this.isFixed = true;
              isChangeFixed = true;
            }
          } else {
            if (this.isFixed) {
              this.isFixed = false;
            }
          }
        }
        if (this.isEnable && this.isFixed) {
          this.isActive = scrollY <= 0 || scrollDirection <= 0;
        }
      });
      $window.scroll();
    },
    methods: {
      openMenu() {
        vueHub.$emit('openMenu');
      }
    }
  });
  toggle = new Vue({
    el: '.l-toggle',
    data: {
      isShow: false
    },
    created() {
      vueHub.$on('openMenu', () => {
        this.isShow = true;
      });
      vueHub.$on('closeMenu', () => {
        this.isShow = false;
      });
    },
    methods: {
      open() {
        vueHub.$emit('openMenu');
      },
      close() {
        vueHub.$emit('closeMenu');
      }
    }
  });
  new Vue({
    el: '.l-toggleWrap',
    data: {
      isShow: false
    },
    created() {
      vueHub.$on('openMenu', () => {
        this.isShow = true;
      });
      vueHub.$on('closeMenu', () => {
        this.isShow = false;
      });
    },
    methods: {
      open() {
        vueHub.$emit('openMenu');
      },
      close() {
        vueHub.$emit('closeMenu');
      }
    }
  });

  $(window).on('unload', function() {
    if (toggle.isShow) {
      vueHub.$emit('closeMenu');
    }
  });
  $('.l-container').on('click', 'a', function(event) {
    const $target = $(event.target),
          thisAnchor = this.href.split('#');

    if (toggle.isShow) {
      if ($target.closest('.l-toggle').length !== 0) {
        if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1 && idRegexp.test(thisAnchor[1])) {
          vueHub.$emit('closeMenu');
        }
      }
    }
  });
}
